import React from 'react';
import Layout from '../components/Layout';
import TitleAndTheme from '../components/TitleAndTheme';
import Grid, { GridThreeAcross } from '../components/Grid';
import AssetWithCaption from '../components/AssetWithCaption';
import ProjectHeader from '../components/ProjectHeader';
import Text from '../components/Text';

const Overview = () => (
  <Layout>
    <TitleAndTheme
      title="Edwin Morris, Genius overview"
      themeClass="theme--overview"
    />

    <ProjectHeader
      name="Genius Overview"
      subhead="What is Genius?"
      sectionid="geniusoverview"
    />

    <Text>
      <p>
        Genius is a lyrics website and media company. Its mission is to serve
        music knowledge, through its website, where lyrics are annotated by a
        community of fans, its videos, where producers and artists talk about
        their process, and its social presence, where it’s followed by millions
        of people on YouTube, Instagram, and Twitter. Genius is one of the most
        popular websites on the internet, with over 100 million monthly
        visitors, and millions of monthly active app users.{' '}
        <a
          rel="noopener noreferrer"
          href="https://genius.com/about"
          target="_blank"
        >
          Learn more about Genius on its about page
        </a>
        .
      </p>
      <p>
        I joined the company in 2014 and stayed through 2019. I worked on a
        rebrand, a cross-platform redesign, and a codebase refactor; introduced
        code, design, and workflow standards; worked with design teams from
        Google, Apple, and Spotify; and led cross-departmental projects.
      </p>
    </Text>

    <Grid>
      <AssetWithCaption
        position="full"
        image="overview/overview_desktop_song"
        width="2880"
        height="1800"
      >
        The Genius song page. Every line has been analyzed and annotated by a
        community of users. Each annotation is collaboratively edited, like a
        Wikipedia page.
      </AssetWithCaption>
    </Grid>

    <Text>
      <p>
        I led the design and front-end development for core pages like the
        mobile song page, the album page, and the video page. I worked with
        other designers on the team on iterations of the song page, which was
        almost constantly being updated and refined.
      </p>
    </Text>

    <GridThreeAcross verticalMargin="bottom">
      <AssetWithCaption
        position="left_third"
        image="overview/overview_mobile_song"
        width="750"
        height="1334"
      />

      <AssetWithCaption
        position="center_third"
        image="overview/overview_mobile_annotation"
        width="750"
        height="1334"
      />

      <AssetWithCaption
        position="right_third"
        image="overview/overview_mobile_search"
        width="750"
        height="1334"
      />
    </GridThreeAcross>

    <Grid verticalMargin="bottom">
      <AssetWithCaption
        position="left_half"
        image="overview/overview_desktop_album"
        width="2880"
        height="1800"
      >
        The album page, a project I led.
      </AssetWithCaption>

      <AssetWithCaption
        position="right_half"
        image="overview/overview_desktop_tate"
        width="2880"
        height="1800"
      >
        An annotation, the core product feature of Genius.
      </AssetWithCaption>

      <AssetWithCaption
        position="left_half"
        image="overview/overview_wapo"
        width="2880"
        height="1800"
      >
        The Web Annotator on a{' '}
        <a
          href="https://www.washingtonpost.com/news/the-fix/wp/2016/10/19/the-final-trump-clinton-debate-transcript-annotated"
          target="_blank"
          rel="noopener noreferrer"
        >
          Washington Post article
        </a>
        .
      </AssetWithCaption>

      <AssetWithCaption
        position="right_half"
        image="overview/overview_desktop_video"
        width="2880"
        height="1800"
      >
        The video page, a project I led.
      </AssetWithCaption>
    </Grid>
  </Layout>
);

export default Overview;
